import React from "react"
import "./SupplierListing.scss"
import useSuppliers from "../../hooks/dataHooks/useSuppliers"
import { Container } from "react-bootstrap"
import SupplierCard from "../SupplierCard/SupplierCard"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import useLoadMore from "../../hooks/useLoadMore"
import LoadMoreButton from "../elements/LoadMoreButton"

const SupplierListing = () => {
  const { isMobile, isTablet } = useDeviceMedia()
  const itemsPerPage = isMobile ? 8 : isTablet ? 16 : 20

  const suppliers = useSuppliers()

  const { itemsToShow, hasMore, loadMore } = useLoadMore({
    allItems: suppliers,
    itemsPerPage,
  })

  return (
    <div className="supplier-listing-wrap">
      <Container className="supplier-listing-container">
        {itemsToShow.map((supplier, index) => (
          <SupplierCard key={index} supplier={supplier} />
        ))}
        <LoadMoreButton onClick={loadMore} hasMore={hasMore} />
      </Container>
    </div>
  )
}

export default SupplierListing
