import loadable from "@loadable/component"
import clsx from "clsx"
import { motion, useInView } from "framer-motion"
import React from "react"
import { Container } from "react-bootstrap"
import { Parallax } from "react-scroll-parallax"
import { contentVariant } from "../../common/animations"
import ImageModule from "../../modules/ImageModule"
import "./VideoModule.scss"
const PlayVideoButton = loadable(() => import("../PlayVideo/PlayVideoButton"))

const VideoModule = ({ module, imagetransforms, strapi_id }) => {
  const ref = React.useRef(null)
  const isInView = useInView(ref, { amount: 0.1 })

  if (!module) return null

  const { layout, video } = module

  const isFullWidth = layout === "full-width"

  let processedImages = imagetransforms?.video_thumbnail_Transforms
  processedImages ||= JSON.stringify({})

  const imageName = isFullWidth
    ? "page.video_thumbnail.full_width"
    : "page.video_thumbnail.image"

  const CustomContainer = ({ children }) => {
    if (isFullWidth) {
      if (video.video_url) {
        return <div className="video-module full-width">{children}</div>
      } else {
        return (
          <Parallax translateY={[-80, 80]} className="video-module full-width">
            {children}
          </Parallax>
        )
      }
    }
    return <Container className="video-module">{children}</Container>
  }

  return (
    <motion.div
      className={clsx(
        "video-module-wrap",
        isFullWidth ? "section-m-160" : "section-m-120"
      )}
      ref={ref}
      variants={contentVariant}
      initial="initial"
      animate={isInView && "animate"}
    >
      <CustomContainer>
        <div className="thumbnail">
          <ImageModule
            ImageSrc={video.thumbnail}
            title={"video thumbnail"}
            altText={"video thumbnail"}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={imageName}
            strapi_id={strapi_id}
          />
          {video.video_url && <div className="overlay-40"></div>}
        </div>

        <PlayVideoButton videoUrl={video.video_url} />
      </CustomContainer>
    </motion.div>
  )
}

export default VideoModule
