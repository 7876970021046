import clsx from "clsx"
import React from "react"
import "../styles/main.scss"
import { graphql } from "gatsby"
import BannerListing from "../components/BannerListing/BannerListing"
import VenuesListing from "../components/VenuesListing/VenuesListing"
import StoriesListing from "../components/StoriesListing/StoriesListing"
import SupplierListing from "../components/SupplierListing/SupplierListing"
import SEO from "../components/SEO/seo"
import { motion } from "framer-motion"
import { layoutVariant } from "../common/animations"
import VideoModule from "../components/VideoModule/VideoModule"

const ListingTemplate = ({ data, ...props }) => {
  const pageData = data?.strapiPage

  if (!pageData) return null

  const modules = pageData?.modules

  const { title, description } = pageData.banner_section
  const meta_title = pageData?.seo?.meta_title
  const meta_description = pageData?.seo?.meta_description
  const imagetransforms = pageData.imagetransforms
  const strapi_id = pageData.strapi_id

  return (
    <motion.div
      className={clsx("listing-template", pageData.page_class)}
      variants={layoutVariant}
      initial="initial"
      animate="animate"
      exit="exit"
      key={pageData.strapi_id}
    >
      <SEO title={meta_title} description={meta_description} />
      <BannerListing title={title} description={description} />
      {modules?.map(module => (
        <>
          {module.strapi_component === "modules.list-collection" &&
            module.collection === "venues" && <VenuesListing />}
          {module.strapi_component === "modules.st-list-module" && (
            <StoriesListing module={module} />
          )}
          {module.strapi_component === "modules.video-module" && (
            <VideoModule
              module={module}
              imagetransforms={imagetransforms}
              strapi_id={strapi_id}
            />
          )}
          {module.strapi_component === "modules.list-collection" &&
            module.collection === "suppliers" && <SupplierListing />}
        </>
      ))}
    </motion.div>
  )
}

export default ListingTemplate

export const query = graphql`
  query ($page_id: Int) {
    strapiPage(strapi_id: { eq: $page_id }) {
      ...pageFragment
      modules {
        ... on STRAPI__COMPONENT_MODULES_LIST_COLLECTION {
          ...listCollectionFragment
        }
        ... on STRAPI__COMPONENT_MODULES_ST_LIST_MODULE {
          ...storyListComponentFragment
        }
        ... on STRAPI__COMPONENT_MODULES_VIDEO_MODULE {
          ...videoModuleFragment
        }
      }
    }
  }
`
