import React from "react"
import { Container } from "react-bootstrap"
import useStories from "../../hooks/dataHooks/useStories"
import FeaturedStory from "../FeaturedStory/FeaturedStory"
import StoryCard from "../StoryCard/StoryCard"
import "./StoriesListing.scss"
import LoadMoreButton from "../elements/LoadMoreButton"
import useLoadMore from "../../hooks/useLoadMore"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const StoriesListing = ({ module }) => {
  const { isMobile, isTablet } = useDeviceMedia()
  const itemsPerPage = isMobile ? 7 : isTablet ? 8 : 9

  const allStories = useStories()

  const stories = allStories?.filter(
    story => story?.id !== module?.featured_story?.id
  )

  const { itemsToShow, hasMore, loadMore } = useLoadMore({
    allItems: stories,
    itemsPerPage,
  })

  if (!module) return null

  const featuredStory = module?.featured_story

  return (
    <Container className="story-listing-wrap">
      <FeaturedStory story={featuredStory} />
      <div className="story-listing-section">
        {itemsToShow?.map((story, index) => (
          <StoryCard key={index} story={story} />
        ))}
        <LoadMoreButton onClick={loadMore} hasMore={hasMore} />
      </div>
    </Container>
  )
}

export default StoriesListing
