import React from "react"

const LoadMoreButton = ({ hasMore, onClick }) => {
  return hasMore ? (
    <button className="button button-load-more" onClick={onClick}>
      load more
    </button>
  ) : null
}

export default LoadMoreButton
