import { graphql, useStaticQuery } from "gatsby"

const useSuppliers = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSupplier(filter: { publish: { eq: true } }) {
        nodes {
          ...supplierFragment
        }
      }
    }
  `)

  return data.allStrapiSupplier.nodes
}

export default useSuppliers
