import React, { useState } from "react"
import { breakString, parseHTML } from "../../common/utils/utils"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import "./VenueCard.scss"
import { Link, navigate } from "gatsby"
import { venuesURL } from "../../common/utils/urls"
import ImageModule from "../../modules/ImageModule"
import { motion } from "framer-motion"

const VenueCard = ({ venue, index, slider }) => {
  const { isMobile } = useDeviceMedia()

  const [isPointMoved, setIsPointMoved] = useState(false)

  if (!venue) return null
  const {
    name,
    slug,
    tile_image,
    size,
    capacity,
    accomodation_size,
    imagetransforms,
    strapi_id,
  } = venue

  const formattedName = isMobile ? name : parseHTML(breakString(name))

  let processedImages = imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})

  const sliderOnSwipe = {
    onPointerDown: e => {
      if (e.buttons === 2) return
      setIsPointMoved(false)
    },
    onPointerMove: e => {
      if (e.buttons === 2) return
      setIsPointMoved(true)
    },
    onPointerUp: e => {
      if (isPointMoved || e.buttons === 2) return
      navigate(`${venuesURL}${slug}/`)
    },
  }

  return (
    <motion.div
      className="venue-card"
      variants={
        !slider && {
          initial: {
            opacity: 0,
            y: 100,
          },
          animate: {
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.2,
              // delay: index * 0.2,
            },
          },
        }
      }
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
    >
      {slider ? (
        <div className="venue-card-image img-zoom" {...sliderOnSwipe}>
          {/* <img src={tile_image?.url} alt={name} /> */}
          <ImageModule
            ImageSrc={tile_image}
            title={name}
            altText={name}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"venue.tile_image.card_image"}
            strapi_id={strapi_id}
          />
        </div>
      ) : (
        <Link to={`${venuesURL}${slug}/`} className="venue-card-image img-zoom">
          <ImageModule
            ImageSrc={tile_image}
            title={name}
            altText={name}
            imagetransforms={processedImages}
            renderer="srcSet"
            imagename={"venue.tile_image.card_image"}
            strapi_id={strapi_id}
          />
        </Link>
      )}
      <div className="venue-card-content">
        {slider ? (
          <div {...sliderOnSwipe}>
            <h3 className="venue-card-title">{formattedName}</h3>
          </div>
        ) : (
          <Link to={`${venuesURL}${slug}/`}>
            <h3 className="venue-card-title">{formattedName}</h3>
          </Link>
        )}
        <div className="venue-card-details">
          {/* <div className="venue-card-detail-wrap">
            <i className="icon icon-users"></i>
            <p className="venue-detail">{accomodation_size}</p>
          </div> */}
          <div className="venue-card-detail-wrap">
            <i className="icon icon-arrow-4"></i>
            <p className="venue-detail">{size}</p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default VenueCard
