import { graphql, useStaticQuery } from "gatsby"

const useVenues = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiVenue(
        sort: { order: ASC, fields: rank }
        filter: { publish: { eq: true } }
      ) {
        nodes {
          ...venueFragment
        }
      }
    }
  `)

  return data.allStrapiVenue.nodes
}

export default useVenues
