import { graphql, useStaticQuery } from "gatsby"

const useStories = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiStory(filter: { publish: { eq: true } }) {
        nodes {
          ...storyFragment
        }
      }
    }
  `)

  return data.allStrapiStory.nodes
}

export default useStories
