import { format } from "date-fns"
import { Link } from "gatsby"
import React from "react"
import { storiesURL } from "../../common/utils/urls"
import { parseHTML } from "../../common/utils/utils"
import StoryDetailSection from "../StoryCard/StoryDetailSection"
import "./FeaturedStory.scss"
import ImageModule from "../../modules/ImageModule"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const FeaturedStory = ({ story }) => {
  if (!story) return null

  const {
    strapi_id,
    tile_image,
    title,
    slug,
    date,
    venue,
    description,
    imagetransforms,
  } = story
  console.log(
    "🚀 ~ file: FeaturedStory.js:25 ~ FeaturedStory ~ tile_image:",
    tile_image
  )

  const formattedDate = format(new Date(date), "MMM yyyy")

  let processedImages = imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <motion.div
      className="featured-story-wrap"
      variants={contentVariant}
      initial="initial"
      whileInView={{
        ...contentVariant.animate,
        transition: {
          ...contentVariant.animate.transition,
          delay: 0.3,
        },
      }}
    >
      <div className={"featured-story-container"}>
        {tile_image && (
          <Link to={`${storiesURL + slug}/`} className="tile-img img-zoom">
            {/* <img src={tile_image.url} alt="" /> */}
            <ImageModule
              ImageSrc={tile_image}
              title={title}
              altText={title}
              imagetransforms={processedImages}
              renderer="srcSet"
              imagename={"story.tile_image.featured"}
              strapi_id={strapi_id}
            />
          </Link>
        )}
        <div className="content">
          <Link to={`${storiesURL + slug}/`}>
            <h3 className="title">{title}</h3>
          </Link>
          <div className="story-detail-wrap">
            {/* <StoryDetailSection date={formattedDate} venue={venue} /> */}
            <div className="story-description">
              {parseHTML(description?.data?.description)}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default FeaturedStory
