import React from "react"
import "./SupplierCard.scss"
import ImageModule from "../../modules/ImageModule"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const SupplierCard = ({ supplier }) => {
  if (!supplier) return null

  const { name, description, image, imagetransforms, strapi_id } = supplier

  let processedImages = imagetransforms?.image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <motion.div
      className="supplier-card"
      variants={contentVariant}
      initial="initial"
      whileInView={{
        ...contentVariant.animate,
        transition: {
          ...contentVariant.animate.transition,
          duration: 0.6,
        },
      }}
      viewport={{ once: true }}
    >
      <div className="supplier-card__image">
        {/* <img src={image.url} alt={supplier.name} /> */}
        <ImageModule
          ImageSrc={image}
          title={name}
          altText={name}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"supplier.image.card_image"}
          strapi_id={strapi_id}
        />
      </div>
      <div className="supplier-card__content">
        <h3 className="supplier-card__content__name">{name}</h3>
        <p className="supplier-card__content__description">{description}</p>
      </div>
    </motion.div>
  )
}

export default SupplierCard
