import React from "react"
import "./VenuesListing.scss"
import useVenues from "../../hooks/dataHooks/useVenues"
import { Container } from "react-bootstrap"
import VenueCard from "../VenueCard/VenueCard"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import useLoadMore from "../../hooks/useLoadMore"
import LoadMoreButton from "../elements/LoadMoreButton"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const VenuesListing = () => {
  const { isTablet } = useDeviceMedia()
  const itemsPerPage = isTablet ? 14 : 16

  const allVenues = useVenues()

  const { itemsToShow, hasMore, loadMore } = useLoadMore({
    allItems: allVenues,
    itemsPerPage,
  })

  return (
    <div className="venues-listing-wrap">
      <Container>
        <motion.div
          className="venues-listing-container"
          variants={contentVariant}
          initial="initial"
          whileInView={{
            ...contentVariant.animate,
            transition: {
              ...contentVariant.animate.transition,
              delay: 0.3,
              staggerChildren: 0.2,
            },
          }}
          viewport={{ once: true }}
        >
          {itemsToShow?.map((venue, index) => (
            <VenueCard venue={venue} index={index} />
          ))}
        </motion.div>
        <LoadMoreButton onClick={loadMore} hasMore={hasMore} />
      </Container>
    </div>
  )
}

export default VenuesListing
