import React from "react"
import { Link } from "gatsby"
import "./StoryCard.scss"
import { format } from "date-fns"
import { storiesURL, venuesURL } from "../../common/utils/urls"
import StoryDetailSection from "./StoryDetailSection"
import ImageModule from "../../modules/ImageModule"
import { motion } from "framer-motion"
import { contentVariant } from "../../common/animations"

const StoryCard = ({ story, slider }) => {
  if (!story) return null
  const { tile_image, title, venue, date, slug, imagetransforms, strapi_id } =
    story

  let processedImages = imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})

  const formattedDate = format(new Date(date), "MMM yyyy")

  return (
    <motion.div
      className="story-card"
      variants={!slider && contentVariant}
      initial="initial"
      whileInView={
        !slider && {
          ...contentVariant.animate,
          transition: {
            ...contentVariant.animate.transition,
            duration: 0.2,
          },
        }
      }
      viewport={{ once: true }}
    >
      <Link to={`${storiesURL + slug}/`} className="story-card-image img-zoom">
        {/* <img src={tile_image?.url} alt={title} /> */}
        <ImageModule
          ImageSrc={tile_image}
          title={title}
          altText={title}
          imagetransforms={processedImages}
          renderer="srcSet"
          imagename={"story.tile_image.card_image"}
          strapi_id={strapi_id}
        />
        <div className="overlay-20" />
      </Link>
      <div className="story-card-content">
        <Link to={`${storiesURL + slug}/`}>
          <h3 className="story-card-title">{title}</h3>
        </Link>
        {/* <StoryDetailSection date={formattedDate} venue={venue} /> */}
      </div>
    </motion.div>
  )
}

export default StoryCard
